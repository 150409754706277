<template>
  <Card :with-header="false" :with-footer="isActionable">
    <template v-slot:default>
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-7 col-md-5">
          <h3 v-bind:class="{'text-success': item.isConfirmed(), 'text-warning': item.isPartiallyConfirmed(), 'text-danger': item.isUnconfirmed()}" class="font-weight-semibold m-0"> {{ item.getProduct().getDisplayName() }}</h3>
        </div>
        <div class="col-5 col-md-3"><BatchItemQtySelectInput v-bind:disabled="!editable" @input="updateConfirmedQty" :item="item" :multiplier="item.hasPacking() ? item.getPacking().getValue() : 1" /> </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <span class="d-flex">{{$t('frontoffice.farmers.batches.summary.product_price')}} <Price class="d-block ml-1" :amount="item.getProduct().getSellingPriceWithVat()" :currency="item.getProduct().getCurrency()"></Price></span>
        </div>
      </div>
      <div v-if="item.hasPackingName()" class="row mt-1">
        <div class="col-12">
          <span class="d-block">{{ formatQty(item.getPackedQty(), item.getPackingName(), false) }}</span>
        </div>
      </div>
      <div v-if="batch.isPrepacking()" class="row mt-1">
        <div class="col-12">
          <span class="d-block">{{$t('frontoffice.farmers.batches.summary.initial_qty', {qty: formatQty(item.getInitialQty(), item.getUnit(), item.isBulk())})}}</span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-bind:class="item.isReviewedAt() && 'w-100'" class="mb-2 mb-sm-0 w-100 w-sm-auto" v-if="isActionable" :disabled="!editable" @click="updateItem(item)" :name="$t('frontoffice.farmers.batches.buttons.confirm', {nr: formatQty(item.getConfirmedQty()), unit: item.getProduct().getUnit()})" type="confirm" />
      <Button :disabled="!editable" v-if="!item.isReviewedAt()" class="w-100 w-sm-auto" @click="unconfirm" name="frontoffice.farmers.batches.buttons.unconfirm" type="delete" />
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import BatchItemQtySelectInput from "@/components/batch/BatchItemQtySelectInput";
import Button from "@/components/elements/Button";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import Price from "@/components/elements/Price";

export default {
  name: "BatchItemCard",
  emits: ['update'],
  mixins: [NumbersMixin],
  components: {Price, BatchItemQtySelectInput, Card, Button},
  props: {
    item: Object,
    batch: Object,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isActionable: function() {
      if (this.item.isChanged()) {
        return true;
      }

      if (this.batch.isSentToFarm() && !this.item.isReviewedAt()) {
        return true;
      }

      return false;
    },
    rerender: function () {
      return this.$store.state.rerender;
    },
  },
  methods: {
    updateItem: function (item) {
      this.$emit('update', item);
      item.setChanged(false);
    },
    updateConfirmedQty: function (reference, value) {
      this.item.setConfirmedQty(value);
      this.item.setQty(value);
    },
    unconfirm()
    {
      this.updateConfirmedQty(this.item, 0);
      this.$emit('update', this.item);
    },
  }
}
</script>

<style scoped>
</style>
