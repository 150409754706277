<template>
  <dl class="row mb-0">
    <dt v-if="withOrderNr" class="col-6 col-md-3 mb-1">{{$t('frontoffice.farmers.order_nr')}}</dt>
    <dd v-if="withOrderNr" class="col-6 col-md-9 mb-1">
      {{ batch.getId() }}
    </dd>
    <dt v-if="withTotal" class="col-6 col-md-3 mb-1">{{$t('frontoffice.farmers.batches.summary.total')}}</dt>
    <dd v-if="withTotal" class="col-6 col-md-9 mb-1">
      <Price :amount="batch.getTotalPriceWithVat()" :currency="batch.getCurrency()" />
    </dd>
    <dt v-if="withCount" class="col-6 col-md-3 mb-1">{{$t('frontoffice.farmers.batches.summary.items')}}</dt>
    <dd v-if="withCount" class="col-6 col-md-9 mb-1">
      <Qty element-style="col-3 p-0" :amount="batch.getItemsWithQty().length" />
    </dd>
    <dt v-if="withTotalBatchOrders" class="col-6 col-md-3 mb-1">{{$t('frontoffice.farmers.batches.summary.total_batch_orders_to_collect')}}</dt>
    <dd v-if="withTotalBatchOrders" class="col-6 col-md-9 mb-1">
      <Qty v-if="totalBatchOrders != 0" element-style="col-3 p-0" :amount="totalBatchOrders" />
      <Spinner :simple="true" v-else />
    </dd>
    <dt class="col-6 col-md-3 mb-1">{{$t('frontoffice.farmers.batches.summary.collection_time')}}</dt>
    <dd class="col-6 col-md-9 mb-1">
      <DayTimeEstimation :moment="activeCycle && activeCycle.getCollectBatch()" :hours="batchCollectionHours" />
    </dd>
  </dl>
</template>

<script>
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import Spinner from "@/components/elements/Spinner";

export default {
  name: "BatchSummaryCard",
  components: {Spinner, DayTimeEstimation, Qty, Price},
  props: {
    batch: Object,
    withTotal: {
      type: Boolean,
      default: true
    },
    withCount: {
      type: Boolean,
      default: true
    },
    withOrderNr: {
      type: Boolean,
      default:false,
    },
    withTotalBatchOrders: {
      type: Boolean,
      default: false,
    },
    totalBatchOrders: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    batchCollectionHours() {
      //if the batch does not have collection hours set, fallback to the cycle collection hours
      if (this.batch.getCollectingHours().length)
        return this.batch.getCollectingHours();
      else return [this.activeCycle.getCollectBatch().getTime()];
    }
  }
}
</script>

<style scoped>

</style>
