<template>
  <Card :title="title" :with-footer="withFooter">
    <template v-slot:default>
      <div class="d-inline-flex col-12 justify-content-between">
        <label class="text-capitalize">{{ formatDay(moment.getDayOfWeek()) }}</label>
        <SelectInput :selected="moment.getTime()" :reference="moment" @input="selectNewTime" v-bind:disabled="!editable" default-option="frontoffice.farmers.batches.time.hour" :options="hourOptions" class="col-5 col-md-3"/>
      </div>
    </template>
    <template v-slot:footer>
      <Button @click="$emit('update', moment)" :disabled="!editable" :name="$t('frontoffice.farmers.batches.time.confirm', {type: $t(title).toLocaleLowerCase()})" type="confirm-mb-0" />
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import {DatesMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import SelectInput from "@/components/elements/SelectInput";

export default {
  name: "BatchTimeCard",
  emits: ['update', 'changed'],
  mixins: [NumbersMixin, DatesMixin],
  components: {SelectInput, Card, Button},
  props: {
    title: String,
    moment: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    withFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hourOptions: function () {
      let times = this.computeTimeIncrements(this.moment.getTime(), 3);
      let options = [];

      times.forEach((time) => options.push({
        val: time,
        txt: time,
      }));

      return options;
    },
  },
  methods: {
    selectNewTime: function (reference, value) {
      reference.setChangedTime(value);
      this.$emit('changed');
    },
  }
}
</script>

<style scoped>

</style>