<template>
  <div class="progress rounded-round">
    <div class="progress-bar progress-bar-striped bg-teal-700" v-bind:style="'width: '+percentage+'%'">
      <span v-if="percentage > 15">{{ $t('general.progress', {nr: formatNumber(percentage) })}}</span>
    </div>
  </div>
</template>

<script>
import {NumbersMixin} from "@/mixins/GeneralMixin";

export default {
  name: "ProgressBar",
  mixins: [NumbersMixin],
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percentage: function () {
      return this.progress * 100 / (this.max - this.min)
    }
  }
}
</script>

<style scoped>
.progress-bar {
}
.progress-bar span {
  display: block;
  text-align: center;
  font-size: 0.875em;
  padding: 3px;
  box-sizing: border-box;
}
</style>
