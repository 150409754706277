<template>
  <SelectInput v-bind:disabled="disabled" @input="onInput" :reference="item" :options="values" :selected="item.getConfirmedQty()" />
</template>

<script>
import SelectInput from "@/components/elements/SelectInput";
import {NumbersMixin} from "@/mixins/GeneralMixin";

export default {
  name: "BatchItemQtySelectInput",
  mixins: [NumbersMixin],
  components: {SelectInput},
  emits: ['input'],
  props: {
    item: Object,
    multiplier: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    values: function () {
      if (null == this.item) {
        return [];
      }

      let values = [];
      let maxLimit = this.item.getInitialQty() / (0 == this.multiplier ? 1 : this.multiplier);

      for (let it = 0; it < maxLimit; it++) {
        values.push({
          val: it * this.multiplier,
          txt: this.formatQty(it * this.multiplier) + (this.item.isBulk() ? ' ' + this.item.getProduct().getUnit() : ' x ' + this.item.getProduct().getUnit()),
        });
      }

      values.push({
        val: this.item.getInitialQty(),
        txt: this.formatQty(this.item.getInitialQty()) + (this.item.isBulk() ? ' ' + this.item.getProduct().getUnit() : ' x ' + this.item.getProduct().getUnit()),
      });

      values.push({
        val: this.item.getConfirmedQty(),
        txt: this.formatQty(this.item.getConfirmedQty()) + (this.item.isBulk() ? ' ' + this.item.getProduct().getUnit() : ' x ' + this.item.getProduct().getUnit()),
      });

      values.sort(function (a, b)  {
        if (a.val < b.val) {
          return -1;
        }
        if  (a.val > b.val) {
          return 1;
        }

        return 0;
      });

      return [...new Map(values.map(item =>
          [item['val'], item])).values()];
    },
  },
  methods: {
    onInput(reference, value) {
      this.$emit('input', reference, value);
    }
  }
}
</script>

<style scoped>

</style>