<template>
  <a  :href="whatsappInviteLink" class="btn btn-success btn-labeled btn-labeled-left">
    <b ><i class="icon-share2"></i></b>
    <span>{{label}}</span>
  </a>
</template>

<script>
export default {
  name: "WhatsappButton",
  props: {
    label: {
      type: String,
      required: true
    },
    shareText: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    whatsappInviteLink() {
      return this.whatsappLink(this.shareText, this.phone);
    },
  },
  methods: {
    whatsappLink(text, phone = '') {
      let url = 'https://api.whatsapp.com/send?text=' +encodeURIComponent(text);
      if (phone) url += '&phone='+encodeURIComponent(phone);
      return url;
    }

  }
}
</script>

<style scoped>

/*
.btn.btn-danger.w-100.btn-labeled-left span {
  margin-left: -36px;
}

.btn.w-100.btn-labeled-right span {
  margin-right: -36px;
}
*/
.btn-success.verde {
  background-color: #17393a;
}

.btn-labeled {
  display: flex;
  justify-content: center;
  padding: 0px;
  align-items: stretch;
}
.btn-labeled > b {
  position: relative;
  display: flex;
  align-items: center;
  top: initial;
  left: initial;
  right: initial;
}
.btn-labeled > span {
  flex-grow: 1;
  padding: .4375rem .875rem;
}

</style>
