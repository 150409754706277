<template>
  <Modal v-if="showWarningModal" :dismissible="false">
    <template v-slot:default>
      <h3 class="d-block text-center text-warning">{{ $t('frontoffice.farmers.pack.order.warning_collected', {batch: batch && batch.getId()}) }}.</h3>
      <h4 class="d-block text-center">{{ $t('frontoffice.farmers.pack.order.warning_collected_subtitle', {email: 'contact@verdefood.co'}) }}.</h4>
      <div class="my-2">
        <Button class="w-100" @click="$router.push({name: 'general.breadcrumb.batches.edit', params: {batchId: batch.getId()}})" type="show" :name="$t('frontoffice.farmers.pack.order.overview')"/>
      </div>
      <div class="my-2">
        <whatsapp-button :label="$t('frontoffice.farmers.share.button_label')" :share-text="$t('frontoffice.farmers.share.share_text')" />
      </div>
      <h3 class="d-block text-right">{{ $t('frontoffice.farmers.pack.order.thanks') }}.</h3>
    </template>
  </Modal>
  <Modal v-else-if="showSuccessModal" :dismissible="false">
    <template v-if="isProcessAllowed" v-slot:default>
      <Card class="border-light border-0 shadow-none" :title="titleStatus"
            :with-footer="false">
        <template v-slot:default>
          <BatchSummaryCard :with-order-nr="true" :batch="batch" :with-count="false" />
          <BlockQuote v-if="isSentToFarm" title="frontoffice.farmers.batches.modal.confirmed" />
          <div class="my-2">
            <Button class="w-100" @click="$router.push({name: 'general.breadcrumb.batches.edit', params: {batchId: batch.getId()}})" type="show" :name="$t('frontoffice.farmers.pack.order.overview')"/>
          </div>
          <div class="my-2">
            <whatsapp-button :label="$t('frontoffice.farmers.share.button_label')" :share-text="$t('frontoffice.farmers.share.share_text')" />
          </div>
        </template>
      </Card>
    </template>
    <template v-slot:default v-else>
      <h3 class="d-block text-center">{{ $t('frontoffice.farmers.pack.order.complete') }}.</h3>
      <div class="my-2">
        <Button class="w-100" @click="$router.push({name: 'general.breadcrumb.batches.edit', params: {batchId: batch.getId()}})" type="show" :name="$t('frontoffice.farmers.pack.order.overview')"/>
      </div>
      <div class="my-2">
        <whatsapp-button :label="$t('frontoffice.farmers.share.button_label')" :share-text="$t('frontoffice.farmers.share.share_text')" />
      </div>
      <h3 class="d-block text-right">{{ $t('frontoffice.farmers.pack.order.thanks') }}.</h3>
    </template>
  </Modal>
  <Modal v-else-if="showJustProcessedModal" :dismissible="false">
    <template v-slot:default>
      <Card class="border-light border-0 shadow-none" :title="titleStatus"
            :with-footer="false">
        <template v-slot:default>
          <BatchSummaryCard :batch="batch" :with-order-nr="true" :with-count="false"/>
          <hr class="mt-0">
          <BatchStatusCard :batch="batch"/>

          <div class="my-2">
            <Button class="w-100" @click="$router.push({name: 'general.breadcrumb.batches.edit', params: {batchId: batch.getId()}})" type="show" :name="$t('frontoffice.farmers.pack.order.overview')"/>
          </div>
          <div class="my-2">
            <whatsapp-button :label="$t('frontoffice.farmers.share.button_label')" :share-text="$t('frontoffice.farmers.share.share_text')" />
          </div>

        </template>
      </Card>
    </template>
  </Modal>
  <div class="verde-farmer-top with-sticky-footer-buttons" v-if="batch && (wasJustProcessed || isViewable)">
    <Card :title="titleStatus"
          :subtitle="subtitleStatus"
          :with-footer="false"
          class="bg-verde"
    >
      <template v-slot:header>
        <div class="font-size-lg mb-0 verde row align-items-center justify-content-around font-weight-semibold">
          <!-- <ImmutabilityStatus :object="batch" /> -->
        </div>
      </template>
      <template v-slot:default>
        <div v-if="!showActions">
          <a class="d-inline-block cursor-pointer" v-if="!forceDisplayBatchInfo" @click="forceDisplayBatchInfo = !forceDisplayBatchInfo"><i class="icon-info22 mr-2"></i> {{$t('frontoffice.farmers.batches.more_details')}}</a>
          <a class="d-inline-block cursor-pointer mb-3" v-if="forceDisplayBatchInfo" @click="forceDisplayBatchInfo = !forceDisplayBatchInfo"><i class="icon-cross mr-2 text-warning"></i>{{$t('frontoffice.farmers.batches.less_details')}}</a>
        </div>
        <div v-if="showActions || forceDisplayBatchInfo">
          <BatchSummaryCard :with-order-nr="true" :batch="batch" :with-count="false"/>
          <!--
          <div v-if="showBatchStatusCard">
            <br class="clearfix"/>
            <BatchStatusCard :batch="batch"/>
          </div>
          -->
          <span v-if="batch.isPacking()" class="font-weight-semibold">{{ $t('frontoffice.farmers.batches.packing.order_count', {total: batch.getConfirmedOrdersCollection().getSize()}) }}</span>
        </div>
      </template>
    </Card>

    <div class="d-flex flex-column flex-sm-row justify-content-between my-4" v-if="showActions">
      <Button v-if="batch.isSentToFarm()" @click="showItemCards=true" class="mb-2 mb-sm-0 w-100 w-sm-auto" name="frontoffice.farmers.batches.buttons.confirm_start" type="confirm"/>
      <Button v-if="batch.isSentToFarm()" @click="unconfirmAll" name="frontoffice.farmers.batches.buttons.unconfirm_all" type="delete" class="w-100 w-sm-auto"/>
      <Button v-if="batch.isPacking() && !showItemCards" @click="packingStart" class="mb-2 mb-sm-0 w-100 w-sm-auto" name="frontoffice.farmers.batches.buttons.packing_start" type="confirm"/>
      <Button v-if="batch.isPacking() && !showItemCards && !showBatchStatusCard" @click="$router.push({name: 'frontoffice.breadcrumb.farmers.prepack', params: {id: batch.getFarm().getId(), batchId: batch.getId()}})" :name="$t('frontoffice.farmers.batches.buttons.view_batch')" type="show" class="w-100 w-sm-auto" />

      <Button v-if="batch.isPacking() && !showItemCards && showBatchStatusCard" @click="$router.push({name: 'frontoffice.breadcrumb.farmers.pack', params: {id: batch.getFarm().getId(), batchId: batch.getId()}})" :name="$t('frontoffice.farmers.batches.buttons.hide_batch')" type="hide" class="w-100 w-sm-auto" />

    </div>

    <div v-if="canShowMainContent">
      <div class="card p-2 text-center bg-success verde" v-show="isViewable && (showItemCards || getProgress() > 0)">
          <h2 v-if="isSentToFarm" class="d-block m-0 font-weight-semibold">{{ $t('frontoffice.farmers.batches.product_senttofarm_delimiter') }}</h2>
          <h2 v-if="isPrepacking" class="d-block m-0 font-weight-semibold">{{ $t('frontoffice.farmers.batches.product_prepack_delimiter') }}</h2>
          <div v-if="isSentToFarm" class="d-block p-md-2">
            <ProgressBar :max="batch.getItemCollection().getSize()" :progress="getProgress()"/>
          </div>
      </div>
      <div v-show="showItemCards || getProgress() > 0">
        <BatchItemCard :batch="batch" :editable="isProcessAllowed" @update="updateBatchItem(item)" v-show="showItemCard(it)" v-for="(item, it) in batch.getItems()"
                       :key="item.getId()" :item="item"/>
      </div>
      <div v-show="isProgressComplete(batch.getItemCollection().getSize())" class="card p-0 text-center bg-success verde">
        <h2 class="d-block m-0 font-weight-semibold p-2">{{ $t('frontoffice.farmers.batches.collect_delimiter') }}</h2>
      </div>
      <div v-if="isProgressComplete(batch.getItemCollection().getSize()) && packingMoment && collectingMoment">
        <!-- <BatchTimeCard @changed="packedTimeUpdated=false" @update="updatePackingMoment" :moment="packingMoment" :with-footer="!packedTimeUpdated" :editable="isProcessAllowed" title="frontoffice.farmers.batches.time.packed" /> -->
        <BatchTimeCard @changed="collectionTimeUpdated=false" @update="updateCollectingMoment" :moment="collectingMoment" :with-footer="!collectionTimeUpdated" :editable="isProcessAllowed" title="frontoffice.farmers.batches.time.collected" />

        <Card title="frontoffice.farmers.batches.farm_delivery.title" :with-footer="!deliveryOptionUpdated">
          <template v-slot:default>
            <div class="col-12">
              <p >{{$t('frontoffice.farmers.batches.farm_delivery.question', {date: cycleEstimatedDeliveryDateFormatted, time: activeCycle.getDeliverOrder().getTime()})}}<br />
              </p>
              <InfoNote text="frontoffice.farmers.batches.farm_delivery.info"/>
              <p v-if="deliveryOptionUpdated && !isLoading">
                <a class="btn btn-outline-primary text-primary"  href="javascript:void(0)" @click="deliveryOptionUpdated = false"><span>{{batch.getFarmerCanDeliver() ? $t('frontoffice.farmers.batches.farm_delivery.confirm') : $t('frontoffice.farmers.batches.farm_delivery.reject')}} <i class="icon-pencil ml-2"></i></span></a>
              </p>

            </div>
          </template>
          <template v-slot:footer>
            <Button @click="updateDeliveryOption(true)" class="float-right" :name="$t('frontoffice.farmers.batches.farm_delivery.confirm')" type="confirm-mb-0" />
            <Button @click="updateDeliveryOption(false)"  :name="$t('frontoffice.farmers.batches.farm_delivery.reject')" type="delete" />
          </template>
        </Card>

      </div>
      <div class="py-3" v-if="canActionOnOrder && isSentToFarm">

        <CheckboxInput label="frontoffice.farmers.batches.accept_terms" class="d-block" :value="termsAccepted" @update="termsAccepted=$event" />

      </div>

      <StickyBottom v-if="!showActions" >

        <div class="w-100">
          <Button v-if="isSentToFarm" :disabled="!canSendToNextPhase || !termsAccepted" class="w-100 mx-auto" @click="confirmAll" name="frontoffice.farmers.batches.buttons.confirm_send" type="continue"/>
          <Button v-if="isPrepacking" :disabled="!isProcessAllowed || !areTimesConfirmed" class="w-100 mx-auto" @click="confirmAll" name="frontoffice.farmers.batches.buttons.reconfirm_send" type="continue"/>
        </div>

      </StickyBottom>

    </div>
    <router-view v-if="showItemCards" :batch="batch" @showSuccessModal="forceSuccessModal=true" @showWarningModal="forceWarningModal=true"></router-view>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Batch from "@/entities/Batch";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import BatchSummaryCard from "@/components/batch/frontoffice/BatchSummaryCard";
import BatchStatusCard from "@/components/batch/frontoffice/BatchStatusCard";
import BatchItemCard from "@/components/batch/frontoffice/BatchItemCard";
import ProgressBar from "@/components/elements/ProgressBar";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {DatesMixin, ProgressMixin} from "@/mixins/GeneralMixin";
import BatchTimeCard from "@/components/batch/frontoffice/BatchTimeCard";
import BlockQuote from "@/components/elements/BlockQuote";
import WhatsappButton from "@/components/elements/WhatsappButton";
import CheckboxInput from "@/components/elements/CheckboxInput";
import StickyBottom from "@/components/elements/StickyBottom";
import InfoNote from "@/components/elements/InfoNote";
// import ImmutabilityStatus from "@/components/elements/ImmutabilityStatus";

export default {
  name: "FarmerBatchList",
  components: {
    InfoNote,
    WhatsappButton,
    // ImmutabilityStatus,
    BlockQuote,
    BatchTimeCard,
    StickyBottom,
    CheckboxInput,
    ProgressBar, BatchItemCard, BatchStatusCard, BatchSummaryCard, Button, Card, Modal},
  mixins: [LoadingModalMixin, ProgressMixin, DatesMixin],
  data() {
    return {
      canDeliver: false,
      batch: null,
      packedTimeUpdated: false,
      collectionTimeUpdated: false,
      deliveryOptionUpdated: false,
      packingMoment: null,
      collectingMoment: null,
      farmerCanDeliver: null,

      showItemCards: false,
      wasJustProcessed: false,
      sendToNextPhase: false,
      forceDisplayBatchInfo: false,
      termsAccepted: false,
      forceSuccessModal: false,
      forceWarningModal: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    showJustProcessedModal: function () {
      return !this.showWarningModal && (this.batch && this.wasJustProcessed)
    },
    showSuccessModal: function() {
      return this.forceSuccessModal || (
          this.batch &&
          !this.batch.hasOrdersWithoutInvoice() &&
          (
              this.batch.isConfirmed() ||
              this.batch.isReConfirmed() ||
              this.batch.isReadyForCollection()
          )
      ) && !this.wasJustProcessed
    },
    showWarningModal: function () {
      return this.forceWarningModal || (this.batch && this.batch.hasOrdersWithoutInvoice() && this.batch.isCollected());
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    areTimesConfirmed: function () {
      return  this.deliveryOptionUpdated && this.collectionTimeUpdated && !this.isLoading; //this.packedTimeUpdated &&
    },
    canShowMainContent: function() {
      return this.$router.currentRoute.value.name == 'frontoffice.breadcrumb.farmers.batch' ||
          this.$router.currentRoute.value.name == 'frontoffice.breadcrumb.farmers.prepack';
    },
    isViewableByFarmer: function () {
      return this.isViewable || this.isSentToFarm;
    },
    isViewable: function () {
      return this.isProcessAllowed ||
          this.isPrepacking ||
          this.batch.isPacking() ||
          this.batch.isReadyForCollection();
    },
    isProcessAllowed: function () {
      return this.batch && (this.batch.isSentToFarm() || this.batch.isPrepacking());
    },
    isSentToFarm: function () {
      return this.batch && (this.batch.isSentToFarm() || this.batch.isConfirmed());
    },
    isPrepacking: function() {
      return this.batch && (this.batch.isPrepacking() || this.batch.isReConfirmed());
    },
    showBatchStatusCard: function () {
      return this.batch && (this.batch.isPrepacking() || this.batch.isPacking()) && this.$router.currentRoute.value.name == 'frontoffice.breadcrumb.farmers.prepack';
    },
    showActions: function () {
      return this.batch && (
          (this.batch.isSentToFarm() && !(this.showItemCards || this.getProgress() > 0)) ||
          (this.batch.isPacking() && !this.showItemCards)
      );
    },
    canSendToNextPhase: function () {
      return this.batch && (
          (this.batch.isSentToFarm() && this.batch.hasAllItemsReviewed() && this.areTimesConfirmed) ||
          (this.batch.isPrepacking() && this.batch.hasAllItemsConfirmed() && this.areTimesConfirmed)
      );
    },
    canActionOnOrder: function() {
      return (this.isSentToFarm && this.canSendToNextPhase) || (this.isPrepacking && this.isProcessAllowed)
    },
    titleStatus: function () {
      if (!this.activeCycle || !this.batch) {
        return this.$t('general.loading');
      }

      if (this.batch.isSentToFarm()) {
        return this.$t('frontoffice.farmers.batches.title.new', {farmer: this.batch.getFarm().getName(), delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
      }

      if (this.batch.isConfirmed() && this.wasJustProcessed) {
        return this.$t('frontoffice.farmers.batches.title.new_confirmed', {delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
      }

      if (this.batch.isConfirmed() && !this.wasJustProcessed) {
        return this.$t('frontoffice.farmers.batches.title.confirmed', {delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
      }

      if (this.batch.isPrepacking()) {
        return this.$t('frontoffice.farmers.batches.title.prepack', {farm: this.batch.getFarm().getName(), delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
      }

      if (this.batch.isReConfirmed() && this.wasJustProcessed) {
        return this.$t('frontoffice.farmers.batches.title.prepack_reconfirm', {delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
      }

      if (this.batch.isPacking()) {
        return this.$t('frontoffice.farmers.batches.title.pack', {farm: this.batch.getFarm().getName(), delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())})
      }

      return this.$t('frontoffice.farmers.batches.title.reconfirm', {delivery: this.formatMomentDate(this.activeCycle.getCollectBatch())});
    },

    subtitleStatus: function () {
      if (this.batch.isPrepacking()) {
        return this.$t('frontoffice.farmers.batches.subtitle.prepack');
      }

      return '';
    },
    cycleEstimatedDeliveryDateFormatted: function()
    {
      let date = this.activeCycle.getDeliverOrder().getEstimatedDateTime();
      return this.formatDate(date);
    },

  },
  methods: {
    init() {
      this.getBatch();
    },
    updatePackingMoment: function (moment) {
      this.packedTimeUpdated = true;
      this.packingMoment = moment;
      this.updateBatch();
    },
    updateCollectingMoment: function (moment) {
      this.collectionTimeUpdated = true;
      this.collectingMoment = moment;
      this.updateBatch();
    },
    updateDeliveryOption: function (canDeliver) {
      this.deliveryOptionUpdated = true;
      this.farmerCanDeliver = canDeliver;
      this.updateBatch();
    },
    onLoadingComplete() {

      if (this.sendToNextPhase) {
        this.sendBatchToNextPhase();
        this.updateBatch();
        this.sendToNextPhase = false;
        return;
      }

      if (this.initialise) {
        this.init();
      }
    },
    showItemCard(it)
    {
      return this.getProgress() >= it;
    },
    sendBatchToNextPhase()
    {
      if (this.batch.isSentToFarm()) {
        this.sendBatchToAction(this.$store.state.config.getBatchesConfirmUri(this.batch.getId()));
      }
      else if (this.batch.isPrepacking()) {
        this.sendBatchToAction(this.$store.state.config.getBatchesReConfirmUri(this.batch.getId()));
      }
    },
    sendBatchToAction(uri) {
      let that = this;
      this.queueJob();
      this.axios.put(uri).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.wasJustProcessed = true;
        that.completeJob(true);
      });
    },
    updateBatchItem(item) {
      let that = this;
      this.queueJob();
      that.axios.put(
          this.$store.state.config.getFarmerBatchItemUpdateUri(
              this.batch.getFarm().getId(),
              this.batch.getId(),
              item.getId()
          ), {qty: item.getConfirmedQty(), is_reviewed: this.batch.isSentToFarm(), is_confirmed: this.batch.isPrepacking()}
      ).then(
          () => {
            if (!item.hasReviewedAt()) {
              item.reviewed();
            } else if (!item.hasConfirmedAt()) {
              item.confirmed();
            }
            item.setChanged(false);
            that.addProgress(item.getId());
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    checkCurrentPageAgainstBatch: function (batch) {
      let view = batch.getViewByStatus();
      if (view != null && view != this.$router.name) {
        this.$router.push({name: view});
      }
    },
    getBatch() {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;

            let batch = new Batch(content.data);
            if (batch.getFarm().getId() == that.$route.params.id) {
              that.checkCurrentPageAgainstBatch(batch);
              that.batch = batch;
              that.packingMoment = that.activeCycle.getPackBatch();
              that.collectingMoment = that.activeCycle.getCollectBatch();

              batch.getItems().forEach(item => item.hasFarmerChecked() ? that.addProgress(item.getId()) : null);
              if (that.batch.hasPackingMoments()) {
                that.packingMoment = that.batch.getPackingMoments()[0];
                that.packedTimeUpdated = true;
              }
              if (that.batch.hasCollectingMoments()) {
                that.collectingMoment = that.batch.getCollectingMoments()[0];
                that.collectionTimeUpdated = true;
              }
              if (that.batch.getFarmerCanDeliver() !== null) {
                that.farmerCanDeliver = that.batch.getFarmerCanDeliver();
                that.deliveryOptionUpdated = true;
              }
            }
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
        if (!that.isViewableByFarmer) {
          that.$nextTick(function () {
            that.$router.push({name: 'general.errors.forbidden.title'});
          });
        }
      });
    },
    updateBatch: function () {
      let that = this;

      if (!this.packingMoment || !this.collectingMoment) {
        return;
      }

      let data = {
        packing_moments: [
          {
            id: this.packingMoment.getId(),
            day_of_week: this.activeCycle.getPackBatch().getDayOfWeek(),
            time: this.packingMoment.getChangedTime(),
          }
        ],
        collecting_moments: [
          {
            id: this.collectingMoment.getId(),
            day_of_week: this.activeCycle.getCollectBatch().getDayOfWeek(),
            time: this.collectingMoment.getChangedTime(),
          }
        ],
        farmer_can_deliver: this.farmerCanDeliver
      };

      this.queueJob();
      this.axios.put(this.$store.state.config.getBatchUri(this.$route.params.batchId), data).then(
          response => {
            let content = response.data;
            let batch = new Batch(content.data);
            if (batch.hasPackingMoments()) {
              that.packingMoment = batch.getPackingMoments()[0];
            }
            if (batch.hasCollectingMoments()) {
              that.collectingMoment = batch.getCollectingMoments()[0];
            }
            that.batch = batch;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    packingStart: function () {
      if (this.$router.currentRoute.value.name != 'frontoffice.breadcrumb.farmers.pack') {
        this.$router.push({
          name: 'frontoffice.breadcrumb.farmers.pack',
          params: {id: this.batch.getFarm().getId(), batchId: this.batch.getId()}
        });
      }
      this.showItemCards = true;
    },
    confirmAll: function () {
      let that = this;
      this.sendToNextPhase = true;
      this.batch.getItems().forEach(item => {
        that.updateBatchItem(item);
      });
    },
    unconfirmAll: function () {
      let that = this;
      this.sendToNextPhase = true;
      this.batch.getItems().forEach(item => {
        item.setConfirmedQty(0);
        that.updateBatchItem(item);
      })
    }
   },
}
</script>

<style scoped>

</style>
