<template>
  <div class="d-block col-md-12">
    <h5 v-show="confirmed > 0" class="text-success mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.confirmed', {nr: confirmed})}}
    </h5>
    <h5 v-show="partial > 0" class="text-warning mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.partial', {nr: partial})}}
    </h5>
    <h5 v-show="unconfirmed > 0" class="text-danger mb-0 font-weight-semibold">
      {{$t('frontoffice.farmers.batches.summary.unconfirmed', {nr: unconfirmed})}}
    </h5>
  </div>
</template>

<script>
export default {
  name: "BatchStatusCard",
  props: {
    batch: Object,
  },
  computed: {
    confirmed: function () {
      return this.batch.getItems().filter(item => item.isConfirmed()).length;
    },
    partial: function () {
      return this.batch.getItems().filter(item => item.isPartiallyConfirmed()).length;
    },
    unconfirmed: function () {
      return this.batch.getItems().filter(item => item.isUnconfirmed()).length;
    },
  },
}
</script>

<style scoped>

</style>