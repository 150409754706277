<template>
  <hr v-if="delimited" class="mt-0">
  <blockquote class="blockquote d-flex py-2 mb-0">
    <div class="mr-3 align-self-center">
      <i class="icon-comment-discussion text-muted opacity-25"></i>
    </div>
    <div>
      <p class="mb-1">
        <slot>{{ $t(title) }}</slot>
      </p>
      <footer v-if="footer" class="blockquote-footer">
        <slot name="footer">{{ $t(footer) }}</slot>
      </footer>
    </div>
  </blockquote>
</template>

<script>
export default {
  name: "BlockQuote",
  props: {
    title: {
      type: String,
      default: "",
    },
    footer: {
      type: String,
      default: "",
    },
    delimited: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>
